import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { BaseService } from './base.service';
import { filterBy } from '../core/helpers/filterby';
import { IQuestionarioUGListaModel } from '../models/questionario-ug-lista.model';
import { FiltroQuestionarioUG } from '../models/filtro-questionario-ug.model';
import { IImprimirQuestionarioRespostaUGModel, IListaQuestionarioRespostaUGModel, IQuestionarioUGModel, IVisualizarQuestionarioRespostaUGModel } from '../models/questionario-ug.model';
import { IArquivoModel } from '../models/arquivo.model';


@Injectable({
    providedIn: 'root',
  })

export class QuestionarioUGService extends BaseService{

    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
      }

      public obter(filtro: FiltroQuestionarioUG): Promise<IBaseModel<PaginacaoModel<IQuestionarioUGListaModel[]>>> {
        return this.httpClient
          .get<IBaseModel<PaginacaoModel<IQuestionarioUGListaModel[]>>>(filterBy(filtro,`${this.apiBaseUrl}/QuestionarioUG`))
          .toPromise();
      }

      public async obterPorId(id: number): Promise<IBaseModel<IQuestionarioUGModel>>{
        return this.httpClient
                    .get<IBaseModel<IQuestionarioUGModel>>(`${this.apiBaseUrl}/QuestionarioUG/${id}`)
                    .toPromise();
      }

      public async inserir(id: number, idUG: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${id}/${idUG}/inserir`, {})
          .toPromise();
      }

      public async inserirQuestionarioAPartirDoUltimo(idUG: number, idTipoRelatorio: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${idUG}/${idTipoRelatorio}/inserirCopia`, {})
          .toPromise();
      }

      public async atualizar(data: IQuestionarioUGModel): Promise<IBaseModel<IQuestionarioUGModel>>{
        return this.httpClient
                    .put<IBaseModel<IQuestionarioUGModel>>(`${this.apiBaseUrl}/QuestionarioUG`, data)
                    .toPromise();
      }

      public async reativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${id}/reativar`, {})
          .toPromise();
      }

      public async inativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${id}/inativar`, {})
          .toPromise();
      }

      public async excluir(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .delete<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${id}/excluir`, {})
          .toPromise();
      }

      public async solicitarPreenchimentoQuestionario(id: number, qtdeDiasPrazo: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${id}/${qtdeDiasPrazo}/solicitarPreenchimento`, {})
          .toPromise();
      }
    
      public async enviarArquivo(idUG: number, idTipoQuestionario: number, anexo : IArquivoModel): Promise<IBaseModel<void>> {
        const formData: FormData = new FormData();
    
        formData.append('id', anexo.id.toString());
        formData.append('nomeArquivo', anexo.nomeArquivo);
        formData.append('entidadeArquivo', anexo.entidadeArquivo.toString());
        formData.append('arquivo', anexo.arquivo);
    
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${idUG}/${idTipoQuestionario}/enviarArquivo`,formData, {})
          .toPromise();
      }

      public async Avocar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUG/${id}/avocar`, {})
          .toPromise();
      }
    
      public async DownloadArquivo(idUG: number): Promise<IBaseModel<IArquivoModel>> {
        return this.httpClient
          .post<IBaseModel<IArquivoModel>>(`${this.apiBaseUrl}/QuestionarioUG/${idUG}/obterArquivo`, {})
          .toPromise();
      }

      public async DownloadArquivoPorIdQuestionario(idQuestionario: number): Promise<IBaseModel<IArquivoModel>> {
        return this.httpClient
          .post<IBaseModel<IArquivoModel>>(`${this.apiBaseUrl}/QuestionarioUG/${idQuestionario}/download-arquivo`, {})
          .toPromise();
      }
    
      public ObterQuestionarioPendente(filtro: FiltroQuestionarioUG): Promise<IBaseModel<PaginacaoModel<IListaQuestionarioRespostaUGModel[]>>> {
        return this.httpClient
          .get<IBaseModel<PaginacaoModel<IListaQuestionarioRespostaUGModel[]>>>(filterBy(filtro,`${this.apiBaseUrl}/QuestionarioUG/pre-planejamento`))
          .toPromise();
      }
    
      public async VisualizarRespostaQuestionario(id: number): Promise<IBaseModel<IVisualizarQuestionarioRespostaUGModel[]>>{
        return this.httpClient
                    .get<IBaseModel<IVisualizarQuestionarioRespostaUGModel[]>>(`${this.apiBaseUrl}/QuestionarioUG/visualizar/${id}`)
                    .toPromise();
      }
    
      public async ImprimirRespostaQuestionario(id: number): Promise<IBaseModel<IImprimirQuestionarioRespostaUGModel[]>>{
        return this.httpClient
                    .get<IBaseModel<IImprimirQuestionarioRespostaUGModel[]>>(`${this.apiBaseUrl}/QuestionarioUG/imprimir/${id}`)
                    .toPromise();
      }
}