<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">
						Avaliar Questionário
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-outline card-lightblue">
        <div class="card-header">
          <h3 class="card-title mb-0">
            <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
            <i *ngIf="!this.id" class="far fa-file pr-2"></i> Avaliar Questionário
          </h3>
        </div>

        <div class="card-body">

          <div class="row">
            <div class="form-group col-md-4">
              <label for="pea" class="mb-1">Nome da Funcionalidade </label>
              <input
                type="text"
                disabled
                id="pea"
                class="form-control form-control-sm"
                [ngModel]="model.nomeFuncionalidade"
                />
            </div>
          </div>  

            <div class="row">
              <button
                type="button"
                class="btn btn-danger btn-sm mr-1"
                (click)="onAvaliar()"
                [disabled]="isButtonAvaliarDisabled"> 
                <i class="fas fa-check"></i> Avaliar
              </button>
              <button
              type="button"
              class="btn btn-primary btn-sm mr-1"
              (click)="onConcordar()"> 
              <i class="fas fa-pencil-alt"></i> Concordar
              </button>
              <button
              type="button"
              class="btn btn-primary btn-sm mr-1"
              (click)="onReavaliar()"
              [disabled]="isButtonReAvaliarDisabled">  
              <i class="fas fa-pencil-alt"></i> Reavaliar
              </button>
            </div>      
          
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <form [formGroup]="formGroup">
    <div formArrayName="respostas">
      <table *ngIf="dataSource && displayedColumns.length" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Coluna de Pergunta e Resposta -->
        <ng-container matColumnDef="questionAndResponse">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let index = index" class="question-response-cell">
            <ng-container *ngIf="element.idTipoSecaoOuPergunta === 1">
              <h3>{{ element.pergunta }}</h3>
            </ng-container>
          
            <ng-container *ngIf="element.idTipoSecaoOuPergunta === 2">
              <mat-checkbox [formControl]="getCheckBoxFormControl(index)" (change)="onCheckboxChange($event, element)">
                {{element.pergunta}}
              </mat-checkbox>
              

              <!-- Campo de resposta da pergunta -->
              <mat-form-field appearance="outline" class="response-field">
                <input matInput [formControl]="getRespostaFormControl(index)"  readonly />
              </mat-form-field>
          </ng-container>
          </td>
        </ng-container>

        <!-- Header e Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="card-footer">
        <button
          type="button"
          *ngIf="!visualizar && !imprimir"
          class="btn btn-primary btn-sm mr-1"
          (click)="onEncerrarAvaliacao()"
          [disabled]="!isTodosAvaliados()">
          <i class=""></i> Encerrar Avaliação
        </button>
        <button
        type="button"
        *ngIf="!visualizar && !imprimir"
        class="btn btn-success btn-sm mr-1"
        (click)="onEnviarReplica()"
        [disabled]="!isTodosAvaliados() || isButtonEnviarReplicaDisabled">
        <i class=""></i> Enviar para Réplica
      </button>
      <button
      type="button"
      *ngIf="!visualizar && !imprimir"
      class="btn btn-outline-secondary btn-sm mr-1"
      (click)="onFechar()">
      <i class=""></i> Fechar
      </button>
    </div>
    </div>
  </form>
</div>
