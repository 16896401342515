import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { EnumStatusAvaliacaoQuestionarioUG } from 'src/app/models/enum/status-avaliacao-questionario-ug.enum';
import { EnumTipoAvaliacaoQuestionarioUG } from 'src/app/models/enum/tipo-avaliacao-questionario-ug.enum';
import { AvaliacaoRequest } from 'src/app/models/questionario-ug.model';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';

@Component({
  selector: 'app-modal-contestar',
  templateUrl: './modal-contestar.component.html',
  styleUrls: ['./modal-contestar.component.scss']
})
export class ModalContestarComponent extends BaseFormComponent  implements OnInit {
  form: FormGroup;  // Declarando o FormGroup
  opcoesAvaliacao = [
    { value: EnumStatusAvaliacaoQuestionarioUG.concordo, label: 'Concordo' },
    { value: EnumStatusAvaliacaoQuestionarioUG.concordoParcialmente, label: 'Concordo Parcialmente' },
    { value: EnumStatusAvaliacaoQuestionarioUG.discordo, label: 'Discordo' }
  ];
  avaliacao: AvaliacaoRequest;
  constructor(
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    private tabelaDominioService: TabelaDominioService,
    private dialogRef: MatDialogRef<ModalContestarComponent>,
    public matDialog: MatDialog,
    private fb: FormBuilder,
    private questionarioUGRespostaService: QuestionarioUGRespostaService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      pergunta: [this.data.pergunta],
      resposta: [this.data.resposta],
      respostaRevisada: [''] ,  // Adicione o controle para resposta revisada
      statusAvaliacao: [null] // Adicione o controle para o select
    });
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  cancelar(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();

  }
  salvar(){
    console.log(this.form.value.texto);

    const idsSelecionadosString: string = [this.data.ids].join(',');
    this.avaliacao = {
      statusAvaliacao: this.form.value.statusAvaliacao,
      texto: this.form.value.respostaRevisada,
      tipoAvaliacao: EnumTipoAvaliacaoQuestionarioUG.replica
    };

    this.questionarioUGRespostaService.fazerAvaliacaoOuReplica(idsSelecionadosString, this.avaliacao)
    .then((res) => {
      if (res.sucesso) {
        this.dialogRef.close();
        this.exibirMensagemSucesso('Avaliacao Realizada com Sucesso');
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

}
}